import { getUserToken } from "./auth";

export const handleSubmitComment = async ({ postId, userId, content, isReply, replyCommentId }) => {
  console.log(`handleSubmitComment()`, postId, userId, content, isReply, replyCommentId)
  const token = getUserToken();
  if(token === null) {
    return {error: "You must be logged in to post a comment"}
  }
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  const response = await fetch(`${process.env.BACKEND_API_URL}/postComment/submit`, {
    method: "POST",
    headers, 
    body: JSON.stringify({ postId, userId, content, isReply, replyCommentId })
  });
  const responseJson = await response.json();
  console.log(responseJson);
  return responseJson;
}

export const fetchPostComments = async ({postId}) => {
  console.log(`fetchPostComments()`, postId)
  if(!postId) {
    console.log(`fetchPostComments() - Invalid postId`)
    return {error: "Invalid postId"}
  }
  const response = await fetch(`${process.env.BACKEND_API_URL}/postComment/get/${postId}`);
  const responseJson = await response.json();
  console.log(responseJson);
  return responseJson;
}

export const handleSubmitLike = async ({ userId, likedCommentId, liked }) => {
  console.log(`handleSubmitLike()`, userId, likedCommentId, liked)
  const token = getUserToken();
  if(token === null) {
    return {error: "You must be logged in to like a comment"}
  }
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  const response = await fetch(`${process.env.BACKEND_API_URL}/postComment/like`, {
    method: "POST",
    headers, 
    body: JSON.stringify({ userId, likedCommentId, liked })
  });
  const responseJson = await response.json();
  console.log(responseJson);
  return responseJson;
}