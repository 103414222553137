import React from "react"
import { hashStrToInt } from "../services/helpers";

const colors = [
  "#1B262C",
  "#0F4C75",
  "#3282B8",
  "#2B2E4A",
  "#E84545",
  "#903749",
  "#53354A",
  "#393E46",
  "#A62349",
  "#D07000",
  "#C55300",
  "#96E5D1",
  "#6A2C70",
  "#B83B5E",
  "#F08A5D",
  "#52616B",
  "#1E2022",
  "#000000",
  "#3E065F",
  "#700B97",
  "#8E05C2",
];

const ProfilePic = ({user, height, width}) => {
  let smallMode = false;
  if((height && height < 40) || (width && width < 40)) {
    smallMode = true;
  }

  let userId, username, profilePic;
  if(user) {
    userId = user.userId;
    username = user.username;
    profilePic = user.profilePic;
  }
  // profile pic image
  if(profilePic) {
    return (
      <img className="img-fluid" src={profilePic} alt="user profile" />
    )
  }

  // generate custom profile pic based on first letter of username
  let userColor;
  if(!userId) {
    userColor = colors[0];
  }
  else {
    const userIdAsInt = hashStrToInt(userId, 6);
    userColor = colors[userIdAsInt % colors.length];
  }

  const letterClass = smallMode ? ' sm' : '';

  return (
    <div className="profile-pic custom" style={{backgroundColor: userColor}}>
      {username?.length > 0 && <div className={`letter ${letterClass}`}>{username.charAt(0)}</div>}
    </div>
  )
}

export default ProfilePic
