import React, {useState, useRef} from "react"
import {MdTagFaces} from "@react-icons/all-files/md/MdTagFaces";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useOutsideAlerter } from "../services/helpers";

const EmojiPicker = ({style, onEmojiSelected }) => {
  const wrapperRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  
  const togglePopupVisible = () => {
    if(popupVisible) {
      // clicked on toggle button but already visible
      // do nothing because useOutsideAlerter() will close the popup
      return;
    }
    setPopupVisible(!popupVisible);
  }
  
  const close = () => {
    setPopupVisible(false);
  }
  
  useOutsideAlerter(wrapperRef, close);

  const positioningClass = "down"; // or up
  return (
    <div className="emoji-toggle" style={style}>
      <MdTagFaces className="emoji-btn" onClick={togglePopupVisible} />
      {popupVisible && <div ref={wrapperRef} className={`emoji-popup ${positioningClass}`}>
        <Picker data={data} onEmojiSelect={e => onEmojiSelected(e)} />
      </div>}
    </div>
  )
}

export default EmojiPicker
