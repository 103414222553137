import React, {useState, useContext, useEffect } from "react"
import TextareaAutosize from 'react-textarea-autosize';
import { UserContext } from "../contexts/UserContext";
import ProfilePic from "./ProfilePic";
import {IoMdThumbsUp} from "@react-icons/all-files/io/IoMdThumbsUp";
import EmojiPicker from './EmojiPicker'
import { fetchPostComments, handleSubmitComment, handleSubmitLike } from "../services/comments";
import { timeAgo } from "../services/helpers";
import toast from "react-hot-toast";

const PostComments = ({postId}) => {
  const {user: authedUser} = useContext(UserContext);
  const  [comments, setComments] = useState([]);

  const submitComment = async ({postId, userId, content, isReply, replyCommentId}) => {
    try {
      // user must be authed
      const response = await handleSubmitComment({
        postId,
        userId,
        content,
        isReply,
        replyCommentId,
      });
      if(response && response.message) {
        return {message: response.message};
      }
      else {
        if(response.error) {
          return {error: response.error};
        }
        throw new Error();
      }
    }
    catch (err) {
      return {error: "Error posting comment"};
    }
  }

  const submitLike = async ({userId, likedCommentId, liked}) => {
    try {
      // user must be authed
      const response = await handleSubmitLike({
        userId,
        likedCommentId,
        liked,
      });
      if(response && response.message) {
        return {message: response.message};
      }
      else {
        if(response.error) {
          return {error: response.error};
        }
        throw new Error();
      }
    }
    catch (err) {
      if(liked) {
        return {error: "Error liking comment"};
      }
      else {
        return {error: "Error unliking comment"};
      }
    }
  }

  const fetchComments = async () => {
      try {
          const comments = await fetchPostComments({postId});
          if(Array.isArray(comments)) {
            console.log('setComments()', comments)
            setComments(comments);
          }
          else {
            let errorMsg = "Failed to fetch comments";
            if(comments.error) {
              errorMsg = comments.error;
            }
            throw errorMsg;
          }
      } catch (err) {
        console.log("Failed to fetch comments", err)
      }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  const CommentProfilePic = ({user, width, height}) => {
    const commentPfpStyle = {};

    const setCommentPfpDimensions = (dimension) => {
      const dim = `${dimension}px`;
      commentPfpStyle.width = dim;
      commentPfpStyle.height = dim;
      commentPfpStyle.minWidth = dim;
      commentPfpStyle.minHeight = dim;
      commentPfpStyle.maxWidth = dim;
      commentPfpStyle.maxHeight = dim;
    }
    if(!isNaN(height)) {
      setCommentPfpDimensions(height)
    }
    if(!isNaN(width)) {
      setCommentPfpDimensions(width)
    }

    return (
      <div className="comment-pfp" style={commentPfpStyle}>
        <ProfilePic user={user} width={width} height={height} />
      </div>
    )
  }

  const CommentAddReply = ({handleReplyCancel, parentComment, initialText}) => {
    console.log('initialText', initialText)
    const  [value, setValue] = useState(initialText || "");
    const processValue = () => {
      let newValue = '';
      // remove multiple line breaks
      newValue = value.replace(/(\n\s*?\n)\s*\n/, '$1');
      return newValue;
    }

    const handleCancelClick = () => {
      setValue("");
      handleReplyCancel();
    }

    const submitCommentClick = async () => {
      // empty comment
      if(value === "") {
        return;
      }
      if(!authedUser || !authedUser.userId) {
        console.log(`submitCommentClick() - user not authed`);
        return;
      }
      // user must be authed
      const response = await submitComment({
        postId,
        userId: authedUser.userId,
        content: value,
        isReply: true,
        replyCommentId: parentComment.commentId,
      });
      if(response.message) {
        toast.success(response.message);
        setValue("");
        handleReplyCancel();
        fetchComments();
      }
      else {
        let errorMsg = `Error posting comment`;
        if(response.error) {
          errorMsg = response.error;
        }
        toast.error(errorMsg);
      }
    }

    const replyClass = value.trim().length > 0 ? "" : "disabled";
    return (
      <div className="comment-add-reply-container">
        <CommentProfilePic user={authedUser} height={38} />
        <div className="right-container">
          <TextareaAutosize 
            style={{marginBottom: '4px'}}
            value={processValue(value)} 
            onChange={e => setValue(e.target.value)} 
            placeholder="Add a reply..." 
            className="comment-textarea"  
          />
        <div className="comment-reply-toolbar">
          <div className="start-container">
            <EmojiPicker style={{marginLeft: '2px'}} onEmojiSelected={(e) => setValue(`${value}${e.native}`)} />
          </div>
          <div className="end-container">
            <div onClick={handleCancelClick} onKeyDown={handleCancelClick} role="button" tabIndex={0} className="btn btn-menuitem cancel">CANCEL</div>
            <div onClick={submitCommentClick} onKeyDown={submitCommentClick} role="button" tabIndex={0} className={`btn btn-menuitem ${replyClass}`}>REPLY</div>
          </div>
        </div>
        </div>
      </div>
    )
  }

  const Comment = ({comment, children}) => {
    const  [addReply, setAddReply] = useState(false);
    const  [commentTimeAgo, setCommentTimeAgo] = useState(timeAgo(comment.createdTime));

    useEffect(() => {
      // refresh comment times
      const interval = setInterval(() => {
        setCommentTimeAgo(timeAgo(comment.createdTime));
      }, 30000);
  
      return () => clearInterval(interval);
    });
  

    const handleReplyCancel = () => {
      setAddReply(false);
    }

    const {user: commentUser, commentId, content, likes, isReply} = comment;

    const pfpSize = isReply ? 38 : null;

    const handleAddReply = () => {
      setAddReply(true);
    }

    const handleLikeComment = async () => {
      if(!authedUser || !authedUser.userId) {
        toast("You must be logged in to do that");
        return;
      }
      let liked;
      if(likes.some(l => l.userId === authedUser.userId)) {
        // user has already liked comment so setting liked to false
        liked = false;
      }
      else {
        // user is liking comment
        liked = true;
      }
      const response = await submitLike({userId: authedUser.userId, likedCommentId: commentId, liked});
      if(response.message) {
        //toast.success(response.message);
        fetchComments();
      }
      else {
        let errorMsg = liked ? "Error liking comment" : "Error unliking comment";
        if(response.error) {
          errorMsg = response.error;
        }
        toast.error(errorMsg);
      }
      return;
    }

    const liked = (authedUser && authedUser.userId && likes.some(l => l.userId === authedUser.userId));
    const likedClass = liked ? "liked" : "";

    return (
      <>
        <div className="comment-row">
          <CommentProfilePic user={commentUser} height={pfpSize} />
          <div className="comment-body-container">
            <div className="comment-meta-container">
              <div className="comment-author">{commentUser.username}</div>
              <div className="comment-time">{commentTimeAgo}</div>
            </div>
            <div className="comment-content-container">
              <div className="comment-content">
                {content}
              </div>
            </div>
            <div className="comment-buttons-container">
              <div className="comment-toolbar">
                <button className="comment-like-btn btn-no-style" onClick={handleLikeComment}>
                  <IoMdThumbsUp className={`like-btn ${likedClass}`} />
                </button>
                {likes.length > 0 && <div className="comment-vote-count">{likes.length}</div>}
                <button className="btn-no-style comment-reply-btn" onClick={handleAddReply}>
                  REPLY
                </button>
              </div>
            </div>
            {/* comment is a root comment so show reply controls here */}
            {!isReply && addReply && <CommentAddReply parentComment={comment} handleReplyCancel={handleReplyCancel} />}
            {/* replies are rendered here */}
            {children}
          </div>
        </div>
        {/* comment is a reply comment so show reply controls here */}
        {isReply && addReply && <CommentAddReply parentComment={comment} initialText={`@${commentUser.username} `} handleReplyCancel={handleReplyCancel} />}
      </>
    )
  }

  const AddComment = () => {
    const  [value, setValue] = useState("");
    const  [showToolbar, setShowToolbar] = useState(false);

    const processValue = () => {
      let newValue = '';
      // remove multiple line breaks
      newValue = value.replace(/(\n\s*?\n)\s*\n/, '$1');
      return newValue;
    }

    const handleCancelClick = () => {
      setValue("");
      setShowToolbar(false);
    }

    const submitCommentClick = async () => {
      // empty comment
      if(value === "") {
        return;
      }
      if(!authedUser || !authedUser.userId) {
        console.log(`submitCommentClick() - user not authed`);
        return;
      }
      // user must be authed
      const response = await submitComment({
        postId,
        userId: authedUser.userId,
        content: value,
        isReply: false,
        replyCommentId: null,
      });
      if(response.message) {
        toast.success(response.message);
        setValue("");
        setShowToolbar(false);
        fetchComments();
      }
      else {
        let errorMsg = `Error posting comment`;
        if(response.error) {
          errorMsg = response.error;
        }
        toast.error(errorMsg);
      }
    }

    const replyClass = value.trim().length > 0 ? "" : "disabled";

    return (
      <div className="comment-add-reply-container" style={{marginBottom: '30px'}}>
        <CommentProfilePic user={authedUser} />
        <div className="right-container">
          <TextareaAutosize 
            onFocus={() => setShowToolbar(true)}
            style={{marginBottom: '4px'}}
            value={processValue(value)} 
            onChange={e => setValue(e.target.value)} 
            placeholder="Add a comment..." 
            className="comment-textarea"  
          />
          {showToolbar && <div className="comment-reply-toolbar">
            <div className="start-container">
              <EmojiPicker style={{marginLeft: '2px'}} onEmojiSelected={(e) => setValue(`${value}${e.native}`)} />
            </div>
            <div className="end-container">
              <div onClick={handleCancelClick} onKeyDown={handleCancelClick} role="button" tabIndex={0} className="btn btn-menuitem cancel">CANCEL</div>
              <div onClick={submitCommentClick} onKeyDown={submitCommentClick} role="button" tabIndex={0} className={`btn btn-menuitem ${replyClass}`}>COMMENT</div>
            </div>
          </div>}
        </div>
      </div>
    )
  }

  return (
    <div>
      <h2 className="post-comments-heading bomber alt blue-grad-text">discussion</h2>
      {comments.length === 0 && <div className="no-comments">No comments yet</div>}
      <AddComment />
      {comments.map((c, i) => 
        {
          const {replyComments} = c;
          return (
            <Comment key={i} comment={c}>
              {replyComments?.map((r, j) => 
                {
                  return (<Comment key={`r${j}`} comment={r} />)
                })
                }
            </Comment>
          )
        })}
    </div>
  )
}

export default PostComments
